import { Routes, Route } from "react-router-dom";
import { AdminApplicationContainer } from "../../Components/Admin/AdminApplicationContainer/AdminApplicationContainer";
import { AdminPageList } from "../../Components/Admin/AdminPageList/AdminPageList";
import { ApplicationContainer } from "../../Components/Common/ApplicationContainer/ApplicationContainer";
import { ForceLoginBox } from "../../Components/Common/LoginBox/ForceLoginBox";
import { MicrosoftLoginDataBox } from "../../Components/Common/MicrosoftLoginDataBox/MicrosoftLoginDataBox";
import { SessionExpiredBox } from "../../Components/Common/SessionExpiredBox/SessionExpiredBox";
import { CustomerApplicationContainer } from "../../Components/Customer/CustomerApplicationContainer/CustomerApplicationContainer";
import { CustomerDashboard } from "../../Components/Customer/CustomerDashboard/CustomerDashboard";
import { CustomerUserList } from "../../Components/Customer/CustomerUserList/CustomerUserList";
import { DemoDashboard } from "../../Components/Demo/DemoDashboard/DemoDashboard";
import { PartnerApplicationContainer } from "../../Components/Partner/PartnerApplicationContainer/PartnerApplicationContainer";
import { PartnerDashboard } from "../../Components/Partner/PartnerDashboard/PartnerDashboard";
import { CommonLeadList } from "../../Components/Common/CommonPages/CommonLeadList/CommonLeadList";
import { PartnerCustomerList } from "../../Components/Partner/PartnerCustomerList/PartnerCustomerList";
import { PartnerProductList } from "../../Components/Partner/PartnerProductList/PartnerProductList";
import { PartnerPurchaseOrderList } from "../../Components/Partner/PartnerPurchaseOrderList/PartnerPurchaseOrderList";
import { AdminDashboard } from "../../Components/Admin/AdminDashboard/AdminDashboard";
import { AdminCustomerList } from "../../Components/Admin/AdminCustomerList/AdminCustomerList";
import { AdminTaskSetupInProgressList } from "../../Components/Admin/AdminTaskSetupInProgressList/AdminTaskSetupInProgressList";
import { AdminCustomerEnviromentsList } from "../../Components/Admin/Related/AdminCustomerEnviromentsList/AdminCustomerEnviromentsList";
import { AdminCustomerAppList } from "../../Components/Admin/Related/AdminCustomerAppList/AdminCustomerAppList";
import { AdminCustomerConfigList } from "../../Components/Admin/Related/AdminCustomerConfigurationList/AdminCustomerConfigurationList";
import { AdminCustomerDataList } from "../../Components/Admin/Related/AdminCustomerDataList/AdminCustomerData"
import { ForgotPasswordBox } from "../../Components/Common/ForgotPasswordBox/ForgotPasswordBox";
import { AdminPageDesigner } from "../../Components/Admin/AdminPageDesigner/AdminPageDesigner";
import { AdminItemRequirementList } from "../../Components/Admin/Administrative/AdminItemRequirementList/AdminItemRequirementLIst";
import { AdminSalesHeaderList } from "../../Components/Admin/Administrative/AdminSalesHeaderTemplateList/AdminSalesHeaderTemplateList";
import { AdminSalesLineList } from "../../Components/Admin/Administrative/AdminSalesLineTemplateList/AdminSalesLineTemplate";
import { AdminPartnerList } from "../../Components/Admin/Administrative/AdminPartnerList/AdminPartnerList";
import { AdminEmailSetupList } from "../../Components/Admin/Administrative/AdminEmailSetupList/AdminEmailSetupList";
import { AdminEmailTemplateList } from "../../Components/Admin/Administrative/AdminEmailTemplateList/AdminEmailTemplateList";
import { AdminApplicationLogList } from "../../Components/Admin/Administrative/AdminApplicationLogList/AdminApplicationLogList";
import { CustomerHelpPage } from "../../Components/Customer/CustomerHelpPage/CustomerHelpPage";
import { AdminContractSetupList } from "../../Components/Admin/Administrative/AdminContractSetupList/AdminContractSetupList";
import { PartnerEmailLogList } from "../../Components/Partner/Administrative/PartnerEmailLogList/PartnerEmailLogList";
import { AdminItemList } from "../../Components/Admin/Administrative/AdminItemList/AdminItemList";
import { AdminPartnerBudgetList } from "../../Components/Admin/Administrative/AdminPartnerBudgetList/AdminPartnerBudgetList";
import { AdminPartnerBusinessContractList } from "../../Components/Admin/Administrative/AdminPartnerBusinessContractList/AdminPartnerBusinessContractList";
import { AdminSubscriptionHeaderList } from "../../Components/Admin/Administrative/AdminSubscriptionHeaderList/AdminSubscriptionHeaderList";
import { AdminSubscriptionLineList } from "../../Components/Admin/Administrative/AdminSubscriptionLineList/AdminSubscriptionLineList";
import { AdminFrontEndLogEntryList } from "../../Components/Admin/Administrative/AdminFrontEndLogEntry/AdminFrontEndLogEntry";
import { AdminSalesInvoiceHeaderList } from "../../Components/Admin/Administrative/AdminSalesInvoiceHeaderList/AdminSalesInvoiceHeaderList";
import { AdminSalesInvoiceLineList } from "../../Components/Admin/Administrative/AdminSalesInvoiceLineList/AdminSalesInvoiceLineList";
import { AdminUserGeneralList } from "../../Components/Admin/Administrative/AdminUserGeneralList/AdminUserGeneralList";
import { PartnerSalesInvoiceLineList } from "../../Components/Partner/Administrative/PartnerSalesInvoiceLineList/PartnerSalesInvoiceLineList";
import { PartnerSalesInvoiceHeaderList } from "../../Components/Partner/Administrative/PartnerSalesInvoiceHeaderList/PartnerSalesInvoiceHeaderList";
import { PartnerSubscriptionHeaderList } from "../../Components/Partner/Administrative/PartnerSubscriptionHeaderList/PartnerSubscriptionHeaderList";
import { PartnerSubscriptionLineList } from "../../Components/Partner/Administrative/PartnerSubscriptionLineList/PartnerSubscriptionLineList";
import { AdminCustomerCompanyList } from "../../Components/Admin/Administrative/AdminCustomerCompanyList/AdminCustomerCompanyList";
import { AdminCustomerDomainList } from "../../Components/Admin/Administrative/AdminCustomerDomain/AdminCustomerDomain";
import { AdminCustomerSetupStepList } from "../../Components/Admin/Administrative/AdminCustomerSetupStep/AdminCustomerSetupStep";
import { AdminCSPSubscriptionList } from "../../Components/Admin/Administrative/AdminCSPsubscription/AdminCSPsubscription";
import { AdminProvisioningLineList } from "../../Components/Admin/Administrative/AdminProvisioningLineList/AdminProvisioningLineList";
import { AdminProvisioningLineDetailsList } from "../../Components/Admin/Administrative/AdminProvisioningLineDetailsList/AdminProvisioningLineDetailsList";
import { AdminUserPermissionList } from "../../Components/Admin/Administrative/AdminUserPermissionList/AdminUserPermissionList";
import { AdminCrmSetupList } from "../../Components/Admin/Administrative/AdminCrmSetupList/AdminCrmSetupList";
import { AdminCrmSourceList } from "../../Components/Admin/Administrative/AdminCrmSourceList/AdminCrmSourceList";
import { AdminDataSecurityEntityList } from "../../Components/Admin/DataSecurity/Entity/AdminDataSecurityEntityList";
import { AdminDataSecurityStatusList } from "../../Components/Admin/DataSecurity/Status/AdminDataSecurityStatusList";
import { AdminDataSecurityStatusChangeList } from "../../Components/Admin/DataSecurity/StatusChange/AdminDataSecurityStatusChangeList";
import { AdminDataSecurityRecordLogList } from "../../Components/Admin/DataSecurity/Log/AdminDataSecurityRecordLogList";
import { AdminCustomerInteractionTelemetryList } from "../../Components/Admin/Administrative/AdminCustomerInteractionTelemetryList/AdminCustomerInteractionTelemetryList";
import { AdminCustomerProgressChecklistTemplateList } from "../../Components/Admin/Administrative/AdminCustomerProgressChecklistTemplateList/AdminCustomerProgressChecklistTemplateList";
import { AdminCustomerProgressChecklistList } from "../../Components/Admin/Administrative/AdminCustomerProgressChecklistList/AdminCustomerProgressChecklistList";
import { AdminApplicationTaskList } from "../../Components/Admin/Administrative/AdminApplicationTaskList/AdminApplicationTaskList";
import { AdminUserApplicationTaskList } from "../../Components/Admin/Related/AdminUserApplicationTaskList/AdminUserApplicationTaskList";
import { AdminCrmLossCauseList } from "../../Components/Admin/Administrative/AdminCrmLossCauseList/AdminCrmLossCauseList";
import { AdminCrmCampaignList } from "../../Components/Admin/Administrative/AdminCrmCampaignList/AdminCrmCampaignList";
import { AdminSystemJobQueueEntryList } from "../../Components/Admin/SystemJobQueue/Entry/AdminSystemJobQueueEntryList";
import { AdminSystemJobQueueLogList } from "../../Components/Admin/SystemJobQueue/Log/AdminSystemJobQueueLogList";
import { AdminSystemJobQueueFunctionList } from "../../Components/Admin/SystemJobQueue/Function/AdminSystemJobQueueFunctionList";
import { PartnerSalesBudgetList } from "../../Components/Partner/Administrative/PartnerSalesBudgetList/PartnerSalesBudgetList";
import { CustomerRmrLedgerEntryList } from "../../Components/Customer/CustomerRmrLedgerEntryList/CustomerRmrLedgerEntryList";
import { AdminAzureSetupList } from "../../Components/Admin/Administrative/AdminAzureSetupList/AdminAzureSetupList";
import { AdminApplicationSetupList } from "../../Components/Admin/Administrative/AdminApplicationSetupList/AdminApplicationSetupList";
import { AdminSystemAppList } from "../../Components/Admin/Administrative/AdminSystemAppList/AdminSystemAppList";
import { AdminEmailLogList } from "../../Components/Admin/Related/AdminEmailLogList/AdminEmailLogList";
import { AdminLossRiskLineList } from "../../Components/Admin/Related/AdminLossRiskLineList/AdminLossRiskLineList";
import { AdminCrmSystemUserList } from "../../Components/Admin/Administrative/AdminCrmSystemUserList/AdminCrmSystemUserList";
import { AdminCrmIncidentList } from "../../Components/Admin/Administrative/AdminCrmIncidentList/AdminCrmIncidentList";
import { CustomerIncidentList } from "../../Components/Customer/CustomerIncidentList/CustomerIncidentList";
import { AdminChatbotSetupList } from "../../Components/Admin/Administrative/AdminChatbotSetupList/AdminChatbotSetupList";
import { AdminBusinessCentralSetupList } from "../../Components/Admin/Administrative/AdminBusinessCentralSetupList/AdminBusinessCentralSetup";
import { AdminCSPSetupList } from "../../Components/Admin/Administrative/AdminCSPSetupList/AdminCSPSetupList";
import { AdminDataSecuritySetupList } from "../../Components/Admin/DataSecurity/Setup/AdminDatasecuritySetupList";
import { AdminSecuritySetupList } from "../../Components/Admin/Administrative/AdminSecuritySetupList/AdminSecuritySetupList";
import { AdminHelpdeskDashboard } from "../../Components/Admin/AdminHelpdeskDashboard/AdminHelpdeskDashboard";
import { AdminCrmIncidentTypeList } from "../../Components/Admin/Administrative/AdminCrmIncidentTypeList/AdminCrmIncidentTypeList";
import { AdminQualifiedAnswerList } from "../../Components/Admin/Related/AdminQualifiedAnswerList/AdminQualifiedAnswerList";
import { LeadSurveyList } from "../../Components/Common/CommonPages/LeadSurveyList/LeadSurveyList";
import { AdminCrmLeadInteractionList } from "../../Components/Admin/Related/AdminCrmLeadInteractionList/AdminCrmLeadInteractionList";
import { AdminNotificationSetupList } from "../../Components/Admin/Administrative/AdminNotificationSetupList/AdminNotificationSetupList";
import { AdminNotificationGroupList } from "../../Components/Admin/Related/AdminNotificationGroupList/AdminNotificationGroupList";
import { AdminNotificationGroupMemberList } from "../../Components/Admin/Related/AdminNotificationGroupMemberList/AdminNotificationGroupMemberList";
import { AdminNotificationLogList } from "../../Components/Admin/Related/AdminNotificationLogList/AdminNotificationLogList";
import { AdminSystemEventPublisherList } from "../../Components/Admin/SystemEvent/Publisher/AdminSystemEventPublisherList";
import { AdminSystemEventSubscriberList } from "../../Components/Admin/SystemEvent/Subscriber/AdminSystemEventSubscriberList";
import { AdminSystemEventSetupList } from "../../Components/Admin/SystemEvent/Setup/AdminSystemEventSetupList";
import { AdminSystemEventParameterList } from "../../Components/Admin/SystemEvent/Parameter/AdminSystemEventParameterList";
import { AdminSystemEventFilterList } from "../../Components/Admin/SystemEvent/Filter/AdminSystemEventFilterList";
import { AdminSystemEventVariableList } from "../../Components/Admin/SystemEvent/Variable/AdminSystemEventVariableList";
import { AdminSystemEventLogList } from "../../Components/Admin/SystemEvent/Log/AdminSystemEventLogList";
import { AdminNotificationTemplateList } from "../../Components/Admin/Related/AdminNotificationTemplateList/AdminNotificationTemplateList";
import { AdminNotificationTemplateVariableList } from "../../Components/Admin/Related/AdminNotificationTemplateVariableList/AdminNotificationTemplateVariableList";
import { AdminContentList } from "../../Components/Admin/Administrative/AdminContentList/AdminContentList";
import { SoSmartShortcut } from "../../Components/Common/SoSmartShortcut/SoSmartShortcut";
import { AdminCustomerBusinessCentralCompanyList } from "../../Components/Admin/Related/AdminBusinessCentralCompanyList/AdminBusinessCentralCompanyList";
import { AdminSystemEventTypeList } from "../../Components/Admin/SystemEvent/Type/AdminSystemEvenTypeList";
import { AdminCustomerSubscriptionMatchList } from "../../Components/Admin/Administrative/AdminCustomerSubscriptionMatchList/AdminCustomerSubscriptionMatchList";
import { AdminEnvironmentPackageList } from "../../Components/Admin/Related/AdminEnvironmentPackages/AdminEnvironmentPackageList";
import { AdminSystemCaptionList } from "../../Components/Admin/Related/AdminSystemCaptionList/AdminSystemCaptionList";
import { AdminDsyDataRequestList } from "../../Components/Admin/Related/AdminDsyDataRequestList/AdminDsyDataRequestList";
import { AdminWizardHeaderList } from "../../Components/Admin/Related/Wizard/AdminWizardHeaderList";
import { AdminWizardLineList } from "../../Components/Admin/Related/Wizard/AdminWizardLineList";
import { AdminWizardLineStructureList } from "../../Components/Admin/Related/Wizard/AdminWizardLineStructureList";
import { AdminWizardMappingList } from "../../Components/Admin/Related/Wizard/AdminWizardMappingList";
import { AdminBookingSetupList } from "../../Components/Admin/Administrative/AdminBookingSetupList/AdminBookingSetupList";
import { AdminCrmProfilingSetupList } from "../../Components/Admin/Administrative/AdminCrmProfilingSetupList/AdminCrmProfilingSetupList";
import { AdminSystemPermissionCategoryList } from "../../Components/Admin/Administrative/AdminSystemPermissionCategoryList/AdminSystemPermissionCategoryList";
import { PartnerIncidentList } from "../../Components/Partner/PartnerIncidentList/PartnerIncidentList";
import { AdminUserRefreshTokenList } from "../../Components/Admin/Administrative/AdminUserRefreshTokenList/AdminRefreshTokenEntryList";
import { Homepage, KnownledgeRenderer, Site } from "../../Components/Docs/Old/Docs";
import { AdminBCNProcessTestList } from "../../Components/Admin/Administrative/AdminBCNProcessTestList/AdminBCNProcessTestList";
import { AdminLeadQualifiedAnswerList } from "../../Components/Admin/Related/AdminLeadQualifiedAnswerList/AdminLeadQualifiedAnswerList";
import { AdminAuthenticationSetupList } from "../../Components/Admin/Administrative/AdminAuthenticationSetupList/AdminAuthenticationSetupList";
import { AdminAzureGDAPRelationshipList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPRelationshipList/AdminAzureGDAPRelationshipList";
import { AdminAzureGDAPRoleList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPRoleList/AdminAzureGDAPRoleList";
import { AdminAzureGDAPSecurityGroupList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPSecurityGroupList/AdminAzureGDAPSecurityGroupList";
import { AdminAzureGDAPSecurityGroupRoleList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPSecurityGroupRoleList/AdminAzureGDAPSecurityGroupRoleList";
import { AdminAzureGDAPCustomerRelationshipRoleList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPCustomerRelationshipRoleList/AdminAzureGDAPCustomerRelationshipRoleList";
import { AdminAzureGDAPCustomerRelationshipRoleDetailList } from "../../Components/Admin/Administrative/GDAP/AdminAzureGDAPCustomerRelationshipRoleDetailList/AdminAzureGDAPCustomerRelationshipRoleDetailList";
import { DemoRoleCenter } from "../../Components/Demo/DemoRoleCenter/DemoRoleCenter";
import { PartnerLeadList } from "../../Components/Partner/PartnerLeadList/PartnerLeadList";
import { PartnerBookinAvailabilityList } from "../../Components/Partner/PartnerBookinAvailabilityList/PartnerBookinAvailabilityList";
import { AdminSharepointDirectoryList } from "../../Components/Admin/Administrative/AdminSharepointDirectoryList/AdminSharepointDirectoryList";
import { AdminLeadAutomationProspectList } from "../../Components/Admin/Administrative/AdminLeadAutomationProspectList/AdminLeadAutomationProspectList";
import { AdminProspectList } from "../../Components/Admin/Administrative/AdminProspectList/AdminProspectList";
import { AdminProspectLeadAutomationSetupList } from "../../Components/Admin/Administrative/AdminProspectLeadAutomationSetupList/AdminProspectLeadAutomationSetupList";
import { AdminAuthenticationEntryList } from "../../Components/Admin/Administrative/AdminAuthenticationEntryList/AdminAuthenticationEntryList";
import { AdminCustomerDigitalContractList } from "../../Components/Admin/AdminCustomerDigitalContractList/AdminCustomerDigitalContractList";
import { AdminDigitalContractList } from "../../Components/Admin/Administrative/AdminDigitalContractList/AdminDigitalContractList";
import { AdminOpenAISetupList } from "../../Components/Admin/Administrative/AdminOpenAISetupList/AdminOpenAISetupList";
import { CustomerUnsubscribeCard } from "../../Components/Customer/CustomerUnsubscribeCard/CustomerUnsubscribeCard";
import { CommonLeadQualifierPage } from "../../Components/Common/CommonLeadQualifierPage/CommonLeadQualifierPage";
import { AdminBusinessCentralDefaultAppList } from "../../Components/Admin/Administrative/AdminBusinessCentralDefaultAppList/AdminBusinessCentralDefaultAppList";
import { AdminCustomerQueuedOperationList } from "../../Components/Admin/Related/AdminCustomerQueuedOperationList/AdminCustomerQueuedOperationList";
import { AdminBusinessLineList } from "../../Components/Admin/Administrative/AdminBusinessLineList/AdminBusinessLineList";
import { AdminBusinessLineTagList } from "../../Components/Admin/Administrative/AdminBusinessLineTagList/AdminBusinessLineTagList";
import { AdminCustomerQueuedStatusList } from "../../Components/Admin/Related/AdminCustomerQueuedStatusList/AdminCustomerQueuedStatusList";
import { AdminChatbotProfileList } from "../../Components/Admin/Administrative/AdminChatbotProfileList/AdminChatbotProfileList";
import Layout from "../../Components/Chatbot/layout/Layout";
import Chat from "../../Components/Chatbot/chat/Chat";
import NoPage from "../../Components/Chatbot/NoPage";
import { AdminChatbotTicketInputList } from "../../Components/Admin/Administrative/AdminChatbotTicketInputList/AdminChatbotTicketInputList";
import { AdminBookingServiceList } from "../../Components/Admin/Administrative/AdminBookingServiceList/AdminBookingServiceList";
import { AdminItemProductSkuIdList } from "../../Components/Admin/Related/AdminItemProductSkuIdList/AdminItemProductSkuIdList";
import { CustomerSupportHelper } from "../../Components/Customer/CustomerSupportHelper/CustomerSupportHelper";
import { SmartLeadCard } from "../../Components/Common/SmartLeadCard/SmartLeadCard";
import { AdminUserSettingsList } from "../../Components/Admin/Related/AdminUserSettingsList/AdminUserSettingsList";
import { AdminEmailAttachmentList } from "../../Components/Admin/Administrative/AdminEmailAttachmentList/AdminEmailAttachmentList";
import { AdminChatbotTranslactionList } from "../../Components/Admin/Related/AdminChatbotTranslaction/AdminChatbotTranslactionList";
import { LeadDashboard } from "../../Components/Common/LeadDashboard/LeadDashboard";
import { AdminDashboardLinkList } from "../../Components/Admin/Administrative/AdminDashboardLinkList/AdminDashboardLinkList";
import { AdminCrmStatusCodeList } from "../../Components/Admin/Administrative/AdminCrmStatusCodeList/AdminCrmStatusCodeList";
import { AdminCRMLegalEntityList } from "../../Components/Admin/Administrative/AdminCRMLegalEntityList/AdminCRMLegalEntityList";
import { AdminDistributorList } from "../../Components/Admin/Administrative/AdminDistributorList/AdminDistributorList";
import { AdminLeadIndustryList } from "../../Components/Admin/Related/AdminLeadIndustryList/AdminLeadIndustryList";
import { DocsContent, DocumentationPortal } from "../../Components/Docs/DocumentationPortal";
import { AdminEmailTemplateLanguageList } from "../../Components/Admin/Administrative/AdminEmailTemplateLanguageList/AdminEmailTemplateLanguageList";
import { AdminCountryRegionList } from "../../Components/Admin/Administrative/AdminCountryRegionList/AdminCountryRegionList";
import { AdminPostalCodeList } from "../../Components/Admin/Related/AdminPostalCodeList/AdminPostalCodeList";
import { AdminAssetsList } from "../../Components/Admin/Administrative/AdminAssetsList/AdminAssetsList";
import LoginBox from "../../Components/Common/LoginBox/LoginBox";
import { Dispatcher } from "../../Components/Common/Template/Dispatcher";
import { AdminEmailTemplateStructureList } from "../../Components/Admin/Administrative/AdminEmailTemplateStructureList/AdminEmailTemplateStructureList";
import EmailUnsubscribePage from "../../Components/Common/EmailUnsubscribePage/EmailUnsubscribePage";
import { AdminStripeCouponList } from "../../Components/Admin/Administrative/AdminStripeCouponList/AdminStripeCouponList";
import { AdminPartnerInvoiceSetupList } from "../../Components/Admin/Administrative/AdminPartnerInvoiceSetupList/AdminPartnerInvoiceSetupList";
import { AdminAuthorizedApiKeyList } from "../../Components/Admin/Administrative/AdminAuthorizedApiKeyList/AdminAuthorizedApiKeyList";

export const createApplicationRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginBox />}></Route>
            <Route path="/forcelogin" element={<ForceLoginBox />}></Route>
            <Route path="/ms/afterauth" element={<MicrosoftLoginDataBox />}></Route>
            <Route path="/buy" element={<SoSmartShortcut />}></Route>
            <Route path="/book" element={<SoSmartShortcut />}></Route>
            <Route path="/trial" element={<SoSmartShortcut />}></Route>
            <Route path="/help" element={<SoSmartShortcut />}></Route>
            <Route path="/app" element={<ApplicationContainer />}>
                <Route path="customer" element={<CustomerApplicationContainer />}>
                    <Route path="home" element={<LeadDashboard />}></Route>
                    <Route path="users" element={<CustomerUserList standalone/> }></Route>
                    <Route path="helpPage" element={<CustomerHelpPage /> }></Route>
                    <Route path="incidents" element={<CustomerIncidentList />}></Route>
                    <Route path="unsubscribe" element={<CustomerUnsubscribeCard />}></Route>
                    <Route path="supportHelper" element={<CustomerSupportHelper />}></Route>
                    <Route path="dashboard" element={<CustomerDashboard />}></Route>
                    <Route path="docs/:cultureCode" element={<DocumentationPortal />}>
                        <Route path=":fileName" element={<DocsContent />} />
                    </Route>
                </Route>
                <Route path="admin" element={<AdminApplicationContainer />}>
                    <Route path="home" element={<AdminDashboard />}></Route>
                    <Route path="helpdesk" element={<AdminHelpdeskDashboard />}></Route>
                    <Route path="customer" element={<AdminCustomerList />}></Route>
                    <Route path="taskSetupInProgress" element={<AdminTaskSetupInProgressList />}></Route>
                    <Route path="customerDigitalContract" element={<AdminCustomerDigitalContractList />}></Route>
                    <Route path="common">
                        <Route path="leads" element={<CommonLeadList />}></Route>
                        <Route path="leadSurvey" element={<LeadSurveyList />}></Route>
                        <Route path="leadQualifier" element={<CommonLeadQualifierPage />}></Route>
                    </Route>
                    <Route path="administrative">
                        <Route path="itemRequirement" element={<AdminItemRequirementList />}></Route>
                        <Route path="translations" element={<AdminChatbotTranslactionList />}></Route>
                        <Route path="salesHeader" element={<AdminSalesHeaderList />}></Route>
                        <Route path="salesLine" element={<AdminSalesLineList />}></Route>
                        <Route path="partnerList" element={<AdminPartnerList />}></Route>
                        <Route path="partnerInvoiceSetupList" element={<AdminPartnerInvoiceSetupList />}></Route>
                        <Route path="emailSetup" element={<AdminEmailSetupList />}></Route>
                        <Route path="emailStructure" element={<AdminEmailTemplateStructureList />}></Route>
                        <Route path="emailTemplate" element={<AdminEmailTemplateList />}></Route>
                        <Route path="emailTemplateLanguage" element={<AdminEmailTemplateLanguageList />}></Route>
                        <Route path="emailAttachment" element={<AdminEmailAttachmentList />}></Route>
                        <Route path="emailLog" element={<AdminEmailLogList />}></Route>
                        <Route path="applicationLogEntry" element={<AdminApplicationLogList />}></Route>
                        <Route path="contractSetup" element={<AdminContractSetupList />}></Route>
                        <Route path="customerCompany" element={<AdminCustomerCompanyList />}></Route>
                        <Route path="customerDomain" element={<AdminCustomerDomainList />}></Route>
                        <Route path="customerSetupStep" element={<AdminCustomerSetupStepList />}></Route>
                        <Route path="crmSetupList" element={<AdminCrmSetupList />}></Route>
                        <Route path="crmProfilingSetup" element={<AdminCrmProfilingSetupList />}></Route>
                        <Route path="crmSourceList" element={<AdminCrmSourceList />}></Route>
                        <Route path="crmLossCauseList" element={<AdminCrmLossCauseList />}></Route>
                        <Route path="crmCampaignList" element={<AdminCrmCampaignList />}></Route>
                        <Route path="itemList" element={<AdminItemList />}></Route>
                        <Route path="partnerBudget" element={<AdminPartnerBudgetList />}></Route>
                        <Route path="partnerBusinessContract" element={<AdminPartnerBusinessContractList />}></Route>
                        <Route path="subscriptionHeader" element={<AdminSubscriptionHeaderList />}></Route>
                        <Route path="subscriptionLine" element={<AdminSubscriptionLineList />}></Route>
                        <Route path="demoLog" element={<AdminFrontEndLogEntryList />}></Route>
                        <Route path="invoiceHeaderList" element={<AdminSalesInvoiceHeaderList />}></Route>
                        <Route path="invoiceLineList" element={<AdminSalesInvoiceLineList />}></Route>
                        <Route path="userGeneral" element={<AdminUserGeneralList />}></Route>
                        <Route path="cspSubscription" element={<AdminCSPSubscriptionList />}></Route>
                        <Route path="userPermission" element={<AdminUserPermissionList />}></Route>
                        <Route path="provisioningLine" element={<AdminProvisioningLineList />}></Route>
                        <Route path="provisioningLineDetails" element={<AdminProvisioningLineDetailsList />}></Route>
                        <Route path="applicationTaskList" element={<AdminApplicationTaskList />}></Route>
                        <Route path="interactionTelemetryList" element={<AdminCustomerInteractionTelemetryList />}></Route>
                        <Route path="progressChecklistTemplateList" element={<AdminCustomerProgressChecklistTemplateList />}></Route>
                        <Route path="azureSetup" element={<AdminAzureSetupList />}></Route>
                        <Route path="applicationSetup" element={<AdminApplicationSetupList />}></Route>
                        <Route path="systemAppList" element={<AdminSystemAppList />}></Route>
                        <Route path="crmSystemUserList" element={<AdminCrmSystemUserList />}></Route>
                        <Route path="crmIncidentList" element={<AdminCrmIncidentList />}></Route>
                        <Route path="chatbotSetup" element={<AdminChatbotSetupList />}></Route>
                        <Route path="businesscentralSetup" element={<AdminBusinessCentralSetupList />}></Route>
                        <Route path="businessCentralDefaultApp" element={<AdminBusinessCentralDefaultAppList />}></Route>
                        <Route path="cspSetup" element={<AdminCSPSetupList />}></Route>
                        <Route path="securitySetup" element={<AdminSecuritySetupList />}></Route>
                        <Route path="crmIncidentTypeList" element={<AdminCrmIncidentTypeList />}></Route>
                        <Route path="contentList" element={<AdminContentList />}></Route>
                        <Route path="notificationSetup" element={<AdminNotificationSetupList />}></Route>
                        <Route path="customerSubscriptionMatch" element={<AdminCustomerSubscriptionMatchList />}></Route>
                        <Route path="bookingSetup" element={<AdminBookingSetupList />}></Route>
                        <Route path="bookingService" element={<AdminBookingServiceList />}></Route>
                        <Route path="permissionCategory" element={<AdminSystemPermissionCategoryList />}></Route>
                        <Route path="userRefreshToken" element={<AdminUserRefreshTokenList />}></Route>
                        <Route path="bcnProcessTest" element={<AdminBCNProcessTestList />}></Route>
                        <Route path="authenticationSetup" element={<AdminAuthenticationSetupList />}></Route>
                        <Route path="azureGDAPRelationship" element={<AdminAzureGDAPRelationshipList />}></Route>
                        <Route path="azureGDAPRole" element={<AdminAzureGDAPRoleList />}></Route>
                        <Route path="azureGDAPSecurityGroup" element={<AdminAzureGDAPSecurityGroupList />}></Route>
                        <Route path="azureGDAPSecurityGroupRole" element={<AdminAzureGDAPSecurityGroupRoleList />}></Route>
                        <Route path="azureGDAPCustomerRelationshipRole" element={<AdminAzureGDAPCustomerRelationshipRoleList />}></Route>
                        <Route path="azureGDAPCustomerRelationshipRoleDetail" element={<AdminAzureGDAPCustomerRelationshipRoleDetailList />}></Route>
                        <Route path="sharepointDirectory" element={<AdminSharepointDirectoryList />}></Route>
                        <Route path="leadAutomationProspect" element={<AdminLeadAutomationProspectList />}></Route>
                        <Route path="prospect" element={<AdminProspectList />}></Route>
                        <Route path="prospectLeadAutomationSetup" element={<AdminProspectLeadAutomationSetupList />}></Route>
                        <Route path="authenticationEntry" element={<AdminAuthenticationEntryList />}></Route>
                        <Route path="digitalContract" element={<AdminDigitalContractList />}></Route>
                        <Route path="openaiSetup" element={<AdminOpenAISetupList />}></Route>
                        <Route path="customerRmrLedgerEntry" element={<CustomerRmrLedgerEntryList />}></Route>
                        <Route path="businessLine" element={<AdminBusinessLineList />}></Route>
                        <Route path="businessLine/tag" element={<AdminBusinessLineTagList />}></Route>
                        <Route path="chatbotBusinessLine" element={<AdminChatbotProfileList />}></Route>
                        <Route path="chatbotTicketInput" element={<AdminChatbotTicketInputList />}></Route>
                        <Route path="dashboardLink" element={<AdminDashboardLinkList />}></Route>
                        <Route path="crmStatusCode" element={<AdminCrmStatusCodeList />}></Route>
                        <Route path="crmLegalEntity" element={<AdminCRMLegalEntityList />}></Route>
                        <Route path="distributor" element={<AdminDistributorList />}></Route>
                        <Route path="countryRegion" element={<AdminCountryRegionList />}></Route>
                        <Route path="assets" element={<AdminAssetsList />}></Route>
                        <Route path="backendTranslations" element={<AdminSystemCaptionList />}></Route>
                        <Route path="stripeCouponList" element={<AdminStripeCouponList />}></Route>
                        <Route path="authorizedApiKey" element={<AdminAuthorizedApiKeyList />}></Route>
                    </Route>
                    <Route path="datasecurity">
                        <Route path="setup" element={<AdminDataSecuritySetupList />} />
                        <Route path="entity" element={<AdminDataSecurityEntityList />} />
                        <Route path="status" element={<AdminDataSecurityStatusList />} />
                        <Route path="statusChange" element={<AdminDataSecurityStatusChangeList />} />
                        <Route path="log" element={<AdminDataSecurityRecordLogList />} />
                    </Route>
                    <Route path="systemevent">
                        <Route path="publisher" element={<AdminSystemEventPublisherList />} />
                        <Route path="subscriber" element={<AdminSystemEventSubscriberList/>} />
                        <Route path="setup" element={<AdminSystemEventSetupList />} />
                        <Route path="parameter" element={<AdminSystemEventParameterList />} />
                        <Route path="filter" element={<AdminSystemEventFilterList />} />
                        <Route path="variable" element={<AdminSystemEventVariableList />} />
                        <Route path="log" element={<AdminSystemEventLogList />} />
                        <Route path="type" element={<AdminSystemEventTypeList />} />
                    </Route>
                    <Route path="systemjobqueue">
                        <Route path="entry" element={<AdminSystemJobQueueEntryList />} />
                        <Route path="log" element={<AdminSystemJobQueueLogList />} />
                        <Route path="function" element={<AdminSystemJobQueueFunctionList />} />
                    </Route>
                    <Route path="related">
                        <Route path="customerConfig" element={<AdminCustomerConfigList />}></Route>
                        <Route path="customerEnv" element={<AdminCustomerEnviromentsList />}></Route>
                        <Route path="customerApp" element={<AdminCustomerAppList />}></Route>
                        <Route path="customerData" element={<AdminCustomerDataList />}></Route>
                        <Route path="emailLog" element={<AdminEmailLogList />}></Route>
                        <Route path="user/applicationTask" element={<AdminUserApplicationTaskList />}></Route>
                        <Route path="customer/progressChecklist" element={<AdminCustomerProgressChecklistList />}></Route>
                        <Route path="lossRiskLine" element={<AdminLossRiskLineList />}></Route>
                        <Route path="qualifiedAnswer" element={<AdminQualifiedAnswerList />}></Route>
                        <Route path="crmInteraction" element={<AdminCrmLeadInteractionList />}></Route>
                        <Route path="notificationTemplate" element={<AdminNotificationTemplateList />}></Route>
                        <Route path="notificationGroup" element={<AdminNotificationGroupList />}></Route>
                        <Route path="notificationTemplateVariable" element={<AdminNotificationTemplateVariableList />}></Route>
                        <Route path="notificationGroupMember" element={<AdminNotificationGroupMemberList />}></Route>
                        <Route path="notificationLog" element={<AdminNotificationLogList />}></Route>
                        <Route path="businessCentralCompany" element={<AdminCustomerBusinessCentralCompanyList />}></Route>
                        <Route path="packages" element={<AdminEnvironmentPackageList />}></Route>
                        <Route path="systemCaption" element={<AdminSystemCaptionList/>}></Route>
                        <Route path="dsyDataRequest" element={<AdminDsyDataRequestList/>}></Route>
                        <Route path="leadQualifiedAnswer" element={<AdminLeadQualifiedAnswerList />}></Route>
                        <Route path="queuedOperations" element={<AdminCustomerQueuedOperationList />}></Route>
                        <Route path="customerQueuedStatus" element={<AdminCustomerQueuedStatusList />}></Route>
                        <Route path="itemProductSkuId" element={<AdminItemProductSkuIdList />}></Route>
                        <Route path="userSettings" element={<AdminUserSettingsList />}></Route>
                        <Route path="leadIndustry" element={<AdminLeadIndustryList />}></Route>
                        <Route path="postalCode" element={<AdminPostalCodeList />}></Route>
                    </Route>
                    <Route path="ui">
                        <Route path="wizard">
                            <Route path="header" element={<AdminWizardHeaderList />}></Route>
                            <Route path="lines" element={<AdminWizardLineList />}></Route>
                            <Route path="structures" element={<AdminWizardLineStructureList />}></Route>
                            <Route path="mapping" element={<AdminWizardMappingList />}></Route>
                        </Route>
                        <Route path="customerEnv" element={<AdminCustomerEnviromentsList />}></Route>
                    </Route>
                    <Route path="system">
                        <Route path="pages" element={<AdminPageList />}></Route>
                        <Route path="design/:pageId" element={<AdminPageDesigner />}></Route>
                    </Route>
                </Route>
                <Route path="partner" element={<PartnerApplicationContainer />}>
                    <Route path="home" element={<PartnerDashboard />}></Route>
                    <Route path="customers" element={<PartnerCustomerList />}></Route>
                    <Route path="products" element={<PartnerProductList />}></Route>
                    <Route path="orders" element={<PartnerPurchaseOrderList />}></Route>
                    <Route path="incidents" element={<PartnerIncidentList />}></Route>
                    <Route path="leads" element={<PartnerLeadList />}></Route>
                    <Route path="bookingAvailability" element={<PartnerBookinAvailabilityList />}></Route>
                    <Route path="administrative">
                        <Route path="invoiceHeaderList" element={<PartnerSalesInvoiceHeaderList />}></Route>
                        <Route path="invoiceLineList" element={<PartnerSalesInvoiceLineList />}></Route>
                        <Route path="subscriptionHeader" element={<PartnerSubscriptionHeaderList />}></Route>
                        <Route path="subscriptionLine" element={<PartnerSubscriptionLineList />}></Route>
                        <Route path="emailLog" element={<PartnerEmailLogList />}></Route>
                        <Route path="salesBudget" element={<PartnerSalesBudgetList />}></Route>
                    </Route>
                    <Route path="datasecurity/log" element={<AdminDataSecurityRecordLogList />} />
                </Route>
                <Route path="demo">
                    <Route path="home" element={<DemoDashboard />}></Route>
                    <Route path="roleCenter" element={<DemoRoleCenter />}></Route>
                </Route>
            </Route>
            <Route path="/sessionexpired" element={<SessionExpiredBox />}></Route>
            <Route path="/register" element={<SmartLeadCard />}></Route>
            <Route path="/forgot" element={<ForgotPasswordBox />}></Route>
            <Route path="/setPassword" element={<ForgotPasswordBox />}></Route>
            <Route path="/startdocs" element={<Homepage />}></Route>
            <Route path="/docs" element={<Site />}>
                <Route path=":processModule/:processCode/:testLineNo" element={<KnownledgeRenderer />} />
            </Route>
            <Route path="/chatbot" element={<Layout />}>
                <Route index element={<Chat />} />
                <Route path="*" element={<NoPage />} />
            </Route>
            <Route path="/email/unsubscribe" element={<EmailUnsubscribePage />}></Route>
            <Route path="*" element={<Dispatcher />}></Route>
        </Routes>
    );
};